.croppie-container .cr-viewport {
	border: 1px dashed #d5dee8;
	max-width: 100%;
	/* box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, .2); */
}

.image-preview-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.post-crop-options {
    display: flex;
	justify-content: space-around;
	margin: 2rem 0;
}

.post-crop-options .option {
    background: var(--color-background-upload-image-action);
    border-radius: 3px;
    cursor: pointer;
    padding: .5rem;
    transition: .3s background;
}

.post-crop-options .option:hover {
    background: var(--color-background-upload-image-action-hover);
}

.post-crop-options .option svg, .post-crop-options .option img {
    display: block;
}