.reset-password-form {
    width: 301px;
    margin: 0 auto;
}

.reset-password-form .field {
    margin-bottom: 1rem;
    max-width: 600px;
    margin: 0 auto;
}

.reset-password-form .field input[type=text], .reset-password-form .field input[type=password] {
    width: 100%;
}

.reset-password-form .field .error-message {
    margin: 10px 0;
    font-size: .8rem;
    color: #f0506e;
}

.reset-password-form .reset-password-button {
    display: flex;
    justify-content: center;
}

.reset-password-form .field.reset-password-captcha {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}

.reset-password-form .field.reset-password-button .submit {
    width: 100%;
}