.search-page {
    height: 100%;
}

.search-page .search-area {
    display: flex;
    flex-direction: column;
    height: 100%;
}

@media (min-width: 780px) {
    .search-page .search-area {
        flex-direction: row;
    }
}

.search-page .search-area .sidebar {
    width: 100%;
    background: var(--color-background-search-sidebar);
    /* background: #323338; */
    margin: 2rem 0;
}

@media (min-width: 780px) {
    .search-page .search-area .sidebar {
        width: 380px;
        border-right: 1px solid var(--color-border-search-sidebar);
        margin: 0px;
    }
}

.search-page .search-area .sidebar .show-search-filters {
    text-align: center;
    font-size: .9em;
    cursor: pointer;
    padding: 1em;
}

.search-page .search-area .sidebar .show-search-filters:hover {
    text-decoration: underline;
}

.search-page .search-area .sidebar .filters {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (min-width: 780px) {
    .search-page .search-area .sidebar .filters {
        position: sticky;
        top: 58px;
    }
}

.search-page .search-area .sidebar .filters .search-barr {
    display: flex;
    margin-bottom: 1em;
    min-height: 50px;
    color: #3b3b3b;
}

.search-page .search-area .sidebar .filters .search-barr input {
    flex-grow: 1;
    height: auto;
    border-radius: 5px 0 0 5px;
}

.search-page .search-area .sidebar .filters .search-barr button {
    border-radius: 0 5px 5px 0;
    border: 1px solid #e5e5e5;
    border-left: none;
    transition-property: color, background-color, border;
    transition: .2s ease-in-out;
    cursor: pointer;
    outline: none;
}

.search-page .search-area .sidebar .filters .search-barr input:focus + button, .search-page .search-area .sidebar .filters .search-barr button:focus {
    background-color: #9658fe;
    color: #fff;
    border-color: #9658fe;
}

.search-page .search-area .sidebar .filter {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 1rem;
    padding: .7rem;
    background: var(--color-background-search-sidebar-filter);
    border-bottom: 1px solid var(--color-border-search-sidebar-filter);
}

.search-page .search-area .sidebar .filter.active {
    background: var(--color-background-search-sidebar-filter-active);
}

/* .search-page .sidebar .filter.symbols {
    background: #cecece;
    color: #1a1a1a;
} */

.search-page .search-area .sidebar .filter .title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 1.2em;
    user-select: none;
    cursor: pointer;
}

.search-page .search-area .sidebar .filter .title .icon {
    display: flex;
    border-radius: 3px;
    background: #f2f4f9;
    padding: .6em;
    color: var(--color-text-search-sidebar-filter-icon);
    transition: background .2s ease-out, color .2s ease-out;
}

.search-page .search-area .sidebar .filter .title .icon.active {
    color: #ffffff;
}

/* .search-page .search-area .sidebar .filter.for-sale .title .icon {
    background: #2dcb73;
    color: #ffffff;
} */

.search-page .search-area .sidebar .filter .title .name {
    margin-left: 1em;
    line-height: 1em;
}

.search-page .search-area .sidebar .filter .filter-options {
    margin-top: .7em;
    padding: .7em 0;
    border-top: 1px dashed #5b5d67
}

.search-page .search-area .sidebar .filter .filter-options .option:first-child {
    margin-top: 0;
}

.search-page .search-area .sidebar .filter .filter-options .option {
    margin-top: .5rem;
}

.search-page .search-area .sidebar .filter .filter-options .option .name {
    display: block;
    margin-bottom: 5px;
    font-size: .8rem;
}

.search-page .search-area .sidebar .filter .filter-options .option input {
    width: 100%;
}

.search-page .search-area .sidebar .filter .filter-options .option .input-range {
    flex-grow: 1;
}

.search-page .search-area .main-content {
    flex-grow: 1;
}

.search-page .search-area .main-content .search-results {
    padding: 10px;
}

.form-input-range {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-input-range input {
    width: 0px;
}

.apply-filters {
    background: #222;
    color: #fafafa;
}

.search-filters-divider {
    position: relative;
    width: 100%;
    margin-bottom: 1em;
    text-align: center;
}

.search-filters-divider.link {
    cursor: pointer;
}

.search-filters-divider.link:hover h1 {
    text-decoration: underline;
}

.search-filters-divider:before {
    content: "";
    position: absolute;
    bottom: 50%;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #efefef;
}

.search-filters-divider h1 {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0 20px;
    background-color: #ffffff;
    font-size: 1em;
    font-weight: 300;
}

.search-header {
    position: relative;
    margin: 40px auto;
    text-align: center;
}

@media (min-width: 780px) {
    .search-header {
        text-align: left;
    }
}

.search-header:before {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 35px);
    right: 0;
    bottom: -20px;
    width: 70px;
    height: 2px;
    background-color: #9658fe;
}

@media (min-width: 780px) {
    .search-header:before {
        top: 0;
        left: 0;
        bottom: 0;
        width: 2px;
        height: 100%;
    }
}

.search-header .title {
    font-size: 2em;
    font-weight: 600;
}

@media (min-width: 780px) {
    .search-header .title {
        padding-left: 1em;
        line-height: 1.8em;
    }
}