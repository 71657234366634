.dropzone-container {
  margin-top: 2rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  border-width: 2px;
  border-radius: 5px;
  border-color: var(--color-border-dropzone);
  border-style: dashed;
  background-color: var(--color-background-dropzone);
  color: var(--color-text-dropzone);
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: var(--color-background-dropzone-focus);
}

.dropzone.disabled {
  opacity: 0.6;
}