.avatar-crop-options {
    display: flex;
    justify-content: space-around;
}

.avatar-crop-options .option {
    background: #e8e8e8;
    border-radius: 3px;
    cursor: pointer;
    padding: .5rem;
    transition: .3s background;
}

.avatar-crop-options .option:hover {
    background: #cecece;
}

.avatar-crop-options .option img {
    display: block;
}

.saved-avatar-preview, .upload-avatar-placeholder {
    width: 140px;
    height: 140px;
    margin: 0 auto .5em auto;
}

.saved-avatar-preview .avatar, .upload-avatar-placeholder .avatar {
    width: 100%;
    height: auto;
    border-radius: 3px;
    border: 3px solid #fafafa;
}

.upload-avatar-placeholder .avatar {
    background: #cecece;
    height: 100%;
}

.upload-avatar-placeholder-two {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;
    margin: 0 auto;
    background: #e8e8e8;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.upload-avatar-placeholder-two .avatar {
    border-radius: 3px;
    width: 100%;
}

.upload-avatar-placeholder-two .edit {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    color: #ffffff;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}

.upload-avatar-placeholder-two:hover .edit {
    opacity: 0.7;
}