.image-selector {
    margin-right: 4px;
    position: relative;
}

.image-selector .image-action-icon {
    cursor: pointer;
}

.image-selector .upload-image-preview {
    display: block;
    width: 86px;
    height: 144px;
    min-height: 144px;
    background-color: #d8dde3;
    border: 1px solid #c3c8ce;
}

.image-selector .remove-image {
    position: absolute;
    top: 2px;
    right: 2px;
}

.image-selector .crop-image {
    position: absolute;
    bottom: 2px;
    left: 2px;
}

.selected {
    /* border: 1px solid #1e87f0; */
    border: 1px solid #9658fe;
}

.image-selector .save-image {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffffc7;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* .image-selector .save-icon {

} */

.image-selector .position-controls {
    display: flex;
}

.image-selector .position-controls .left {
    display: inline-flex;
}

.image-selector .position-controls .right {
    display: inline-flex;
    margin-left: auto;
}