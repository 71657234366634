.dropdown-item {
    display: block;
    color: var(--color-text-dropdown-primary);
    margin-bottom: 4px;
    white-space: nowrap;   
    cursor: pointer;
}

.dropdown-item, .dropdown-item a, .dropdown-item a:hover {
    text-decoration: none;
}

.dropdown-item:hover {
    color: var(--color-text-dropdown-secondary);
    text-decoration: inherit;
}

.dropdown-item-divider {
    margin: 5px 0;
    border-top: 1px solid var(--color-background-dropdown-divider);
}