.notification-dropdown-button {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    border-radius: 0 0 5px 5px;
    transition: background 0.1s ease;
    padding: 1rem;

    /* background: #f7f7f7; */
    color: var(--color-text-dropdown-notification-button);
    font-weight: 600;
    border-top: 1px var(--color-border-dropdown-header) solid;
}

.notification-dropdown-button:hover {
    background: var(--color-background-dropdown-notification-button-hover);
}