.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    /* padding: 0.5rem 0; */
}

@media (min-width: 768px) {
    .info {
        flex-direction: row;
        align-items: flex-start;
    }
}   
 

.info .avatar {
    width: 140px;
    height: 140px;
    position: relative;
}

@media (min-width: 768px) {
    .info .avatar {
        margin-top: -35px;
    }
}  

.info .avatar-image {
    width: 100%;
    height: auto;
    border-radius: 3px;
    /* border: 1px solid var(--color-background-body); */
    background: var(--color-background-body);
    box-shadow: 0px 0px 5px 0px #000;
}

.info .avatar .badge {
    display: flex;
    position: absolute;
    bottom: 2px;
    right: 2px;
    background: rgb(14 14 14 / 90%);
    padding: 6px;
    border-radius: 10px;
}

.info .details {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
}

@media (min-width: 768px) {
    .info .details {
        align-items: flex-start;
    }
} 

.info .details .username {
    font-size: 1em;
    font-weight: 500;
    color: var(--color-text-username);
    display: flex;
    align-items: center;
    padding: 0.4rem 0 0.2rem 0;
}

@media (min-width: 768px) {
    .info .details .username {
        padding: 0;
    }
} 

.info.banned .details .username {
    text-decoration: line-through;
}

.info .details .username .verified {
    margin-left: 0.5rem;
}

.info .details .user-group {
    display: flex;
    align-items: center;
    font-size: .44em;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.2rem 0;
}

.info .details .user-group .badge {
    display: flex;
    margin-right: 0.2rem;
}

.info .details .join-date {
    font-size: .44em;
    font-weight: 600;
    color: #545454;
    color: #a2a2a2;
    display: flex;
    text-transform: uppercase;
    padding: 0.2rem 0;
}

@media (min-width: 768px) {
    .info .details .join-date {
        /* padding: 0; */
    }
}

.info .details .metrics {
    font-size: .48em;
    font-weight: 600;
    display: flex;
    text-transform: uppercase;
    padding: 0.2rem 0;
}

.info .details .metrics .follow-type {
    font-weight: 500;
    color: #a2a2a2;
    color: #bbbbbb;
}

.info .details .metrics .following {
    margin-right: 1rem;
}

.info .details .social-media {
    font-size: .4em;
    font-weight: 600;
    color: #545454;
    color: #a2a2a2;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0.4rem 0;
}

.info .details .social-media .site {
    display: flex;
    margin-right: 0.8rem;
}

.info .details .social-media .site:last-child {
    margin-right: 0;
}

.info .details .social-media .site .icon {
    transition: color 0.1s ease-in-out;
}

.info .details .social-media .site .icon.gamersfirst {
    color: #a2a2a2;
}

.info .details .social-media .site .icon.gamersfirst:hover {
    color: #1b8eca;
}

.info .details .social-media .site .icon.twitter:hover {
    color: #00a4ee;
}

.info .details .social-media .site .icon.twitch:hover {
    color: #9147ff;
}

.info .details .social-media .site .icon.youtube:hover {
    color: #ff0000;
}

.info .details .user-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 300;
}

.edit-user-form, .edit-user-characters-form {
    margin: 0 auto;
    /* max-width: 500px; */
}

.edit-user-form .field, .edit-user-characters-form .field {
    margin-bottom: 1rem;
}

.edit-user-form .field input, .edit-user-characters-form .field input {
    width: 100%;
}

.edit-user-characters-form .claim-characters-form {
    max-width: 500px;
    margin: 0 auto;
}

.edit-user-form .field .error-message, .edit-user-characters-form .field .error-message {
    margin: 10px 0;
    font-size: .8rem;
    color: #f0506e;
}

.edit-user-form .edit-user-button, .edit-user-characters-form .edit-user-button {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
}

/* 

TABULAR NAV

*/

/* .tabular-nav a {
  text-decoration: none;
} */

.tabular-nav {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    margin: 1rem 0;
    padding: 0 3px;
    justify-content: center;
}

.tabular-nav:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #e5e5e5;
}

.tabular-nav>* {
    flex: none;
    position: relative;
}

.tabular-nav>.active>span, .tabular-nav>.active>span:hover, .tabular-nav>.active>span:focus {
    color: #222638;
    border-color: #9658fe;
}
.tabular-nav>*>span {
    font-family: 'Khula', sans-serif;
    font-weight: 600;
    display: flex;
    text-align: center;
    padding: 9px 20px;
    color: #999;
    border-bottom: 2px solid transparent;
    font-size: 16px;
    text-transform: uppercase;
    transition: color .1s ease-in-out;
    line-height: 20px;
    cursor: pointer;
}

.tabular-nav>*>span:hover, .switcher-nav>*>span:focus {
    color: #666;
}

.category-icon {
    width: 30px;
    height: 30px;
    display: inline-flex;
}

.category-text {
    display: none;
}

@media (min-width: 760px) {
    .tabular-nav {
        /* justify-content: left; */
    }

    .tabular-nav .tab {
        display: flex;
        align-items: center;
    }

    .category-icon {
        width: 25px;
        height: 25px;
        margin-right: 0.3rem;
    }

    .category-text {
        display: inline-flex;
        margin-top: 3px;
        /* height: 18px; */
    }
    
}

/* New Nav */

.profile-menu {
    display: flex;
    flex-direction: column;
    background: #151517;
    /* width: 100%;
    height: 52px;
    margin: 2rem 0; */
    margin: 1.5rem 0 2rem 0;
}

@media(min-width: 760px) {
    .profile-menu {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 52px;
        background: #151517;
        margin: 2rem 0;
    }
}

.profile-menu .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-weight: 700;
    font-size: 0.8rem;
    text-transform: uppercase;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    transition: border 0.1s ease-in-out;
    letter-spacing: 1px;
    height: 100%;
}

@media(min-width: 760px) {
    .profile-menu .item {
        padding: 0 1rem;
    }
}

.profile-menu .item:hover {
    border-bottom: 3px solid #c7c7da;
}

.profile-menu .item.active {
    border-bottom: 3px solid #7668cb;
}

.profile-menu .right-menu {
    display: flex;
    flex-direction: column;
    border-top: 0.5rem solid #27282c;
}

@media(min-width: 760px) {
    .profile-menu .right-menu {
        flex-direction: row;
        margin-left: auto;
        border-top: 0px;
        border-left: 5px solid #27282c;
    }
}

.profile-menu .right-menu .dropdown_trigger,
.profile-menu .right-menu .options {
    display: flex;
    width: 100%;
    height: 100%;;
}

.profile-menu .right-menu .follow,
.profile-menu .right-menu .message,
.profile-menu .right-menu .options {
    background: transparent;
    border: none;
    transition: background .1s ease-in-out;
}

.profile-menu .right-menu .follow {
    color: #ad7cff;
    user-select: none;
}

@media(min-width: 760px) {
    .profile-menu .right-menu .follow,
    .profile-menu .right-menu .message {
        padding: 0 2rem;
    }
}

.profile-menu .right-menu .follow:hover,
.profile-menu .right-menu .message:hover,
.profile-menu .right-menu .options:hover {
    background: #0c0c0c;
}

.profile-menu .right-menu .follow:active,
.profile-menu .right-menu .follow.following:active,
.profile-menu .right-menu .message:active,
.profile-menu .right-menu .options:active {
    background: #000;
}

.profile-menu .right-menu .follow.following {
    background: #0c0c0c;
}

@media(min-width: 760px) {
    .profile-menu .right-menu .options {
        border-left: 1px solid #27282c;
    }
}