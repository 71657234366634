@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');

:root {
    --breakpoint-sm: 425px;
    --breakpoint-md: 760px;
    --breakpoint-lg: 1020px; 

    /* COLOR DEFINITIONS */
    --color-purple: #9658fe;
    --color-purple-dark: #6d40b9;
    --color-halloween: #ff8517;
    --color-halloween-dark: #c36e12;
    --color-blue: #1e87f0;
    --color-blue-alt: #2196f3;
    --color-blue-dark:  #1377ff;
    --color-error: #f0506e;
    --color-white: #ffffff;
    --color-hinted-grey-1: #fafafa;
    --color-hinted-grey-2: #f3f3f3;
    --color-hinted-grey-3: #e8e8e8;
    --color-hinted-grey-4: #d0d0d0;
    --color-hinted-grey-5: #3b3b3b;
    --color-hinted-grey-6: #2b2d31;
    --color-hinted-grey-7: #27282c;
    --color-hinted-grey-8: #222638;
    --color-hinted-grey-9: #1e1f23;
    --color-hinted-grey-10: #1d1d1d;
    --color-hinted-grey-11: #020202;
    --color-hinted-grey-12: #bdbdbd;
    --color-hinted-grey-13: #cecece;
    --color-hinted-grey-14: #cccccc;
    --color-hinted-grey-15: #333439;
    --color-hinted-grey-16: #1c1d20;
    --color-hinted-grey-17: #27282c;
    --color-hinted-grey-18: #464749;
    --color-hinted-grey-19: #313235;
    --color-hinted-grey-20: #a2a2a2;
    --color-brand-discord: #7289DA;
    --color-brand-discord-alt: #4E5D94;
    --color-brand-twitter: #1DA1F2;
}

:root {
    /* **LIGHT** THEME DEFINITIONS */
    --color-background-body: var(--color-white);
    --color-background-accent: var(--color-purple);
    --color-background-accent-alt: var(--color-blue);
    --color-background-accent-secondary: var(--color-purple-dark);
    --color-background-accent-secondary-alt: var(--color-blue-dark);
    --color-background-image-slider-pagination-bullet: var(--color-purple);
    --color-background-button-primary: transparent;    
    --color-background-button-primary-hover: var(--color-purple);    
    --color-background-button-primary-active: var(--color-purple-dark);    
    --color-background-button-primary-locked: transparent;
    --color-text-button-disabled: #6f6f6f; 
    --color-background-button-disabled: #e5e5e5; 
    --color-text-button-primary: var(--color-purple);    
    --color-text-button-primary-hover: var(--color-white);    
    --color-text-button-primary-active: var(--color-white);    
    --color-text-button-primary-locked: #999999;    
    --color-border-button-primary: var(--color-purple);
    --color-border-button-primary-locked: #e5e5e5;
    
    --color-background-input: #f7f7f7;
    --color-background-input-active: #e4e4e4;
    --color-border-input: var(--color-hinted-grey-3);
    --color-border-input-focus: var(--color-purple);
    --color-text-input: var(--color-hinted-grey-5);

    --color-background-toggle: var(--color-hinted-grey-14);
    --color-background-toggle-checked: var(--color-blue-alt);
    --color-background-toggle-focus: var(--color-blue-alt);
    --color-background-toggle-handle: var(--color-white);

    --color-background-character-table-head: #f7f7f7;
    --color-text-character-table-head: #1a1a1a;
    --color-background-character-table-row: transparent;
    --color-background-character-table-row-hover: #f7f7f7;
    --color-text-character-table: #313a47;

    --color-background-subsection-header: #f7f7f7;
    --color-text-subsection-header: var(--color-hinted-grey-10);

    --color-background-grid-post-image: var(--color-hinted-grey-3);

    --color-background-top-nav: var(--color-hinted-grey-1);
    --color-background-sidebar: var(--color-hinted-grey-1);
    --color-background-sidebar-item-selected: var(--color-hinted-grey-3);
    --color-background-sidebar-item-hover: #7668cb;
    --color-background-sidebar-item-active: #4e4582;

    --color-background-footer: var(--color-hinted-grey-1);
    
    --color-background-dropdown: #fff;
    --color-background-dropdown-notification-hover: #e4e4e4;
    --color-background-dropdown-notification-unread: #fcfdd0;
    --color-background-dropdown-header: #f7f7f7;
    --color-background-dropdown-notification-button-hover: #e4e4e4;
    --color-background-dropdown-divider: #e5e5e5;
    --color-text-dropdown-primary: #3c3c3c;
    --color-text-dropdown-secondary: #666;
    --color-text-dropdown-header: #1a1a1a;
    --color-text-dropdown-notification-button: #1a1a1a;
    --color-border-dropdown-header: #e4e4e4;

    --color-background-upload-image-action: var(--color-hinted-grey-3);
    --color-background-upload-image-action-hover: var(--color-hinted-grey-13);
    --color-background-upload-image-preview: var(--color-hinted-grey-3);
    --color-background-upload-image-preview-action: var(--color-hinted-grey-3);
    --color-background-upload-image-preview-action-hover: var(--color-hinted-grey-13);

    --color-background-dropzone: var(--color-hinted-grey-1);
    --color-background-dropzone-focus: var(--color-blue-alt);
    --color-border-dropzone: var(--color-hinted-grey-4);
    --color-text-dropzone: var(--color-hinted-grey-12);

    --color-background-post-image: var(--color-hinted-grey-3);
    --color-border-post-image: var(--color-hinted-grey-3);
    --color-background-post-image-preview: var(--color-hinted-grey-3);
    --color-border-post-image-preview: var(--color-hinted-grey-3);

    --color-border-post-description: var(--color-hinted-grey-3);

    --color-text-post-tags: #616161;

    --color-background-post-sell-price: #e9dcff;
    --color-text-post-sell-price: var(--color-hinted-grey-5);

    --color-background-post-comment: var(--color-hinted-grey-16);
    --color-text-post-comment-time: #3c3c3c;

    --color-border-top-nav: var(--color-hinted-grey-2);
    --color-border-sidebar: var(--color-hinted-grey-2);
    --color-border-footer: var(--color-hinted-grey-2);

    --color-border-grid-post-image: var(--color-hinted-grey-3);
    --color-border-grid-post-image-hover: var(--color-purple);

    --color-text-body: var(--color-hinted-grey-5);
    --color-text-top-nav: var(--color-hinted-grey-5);
    --color-text-top-nav-hover: #7668cb;

    --color-text-sidebar: var(--color-hinted-grey-5);

    --color-text-section-head: var(--color-hinted-grey-11);
    --color-text-section-sub-head: var(--color-hinted-grey-11);
    --color-text-section-sub-section: var(--color-hinted-grey-10);

    --color-text-post-creator-author: var(--color-hinted-grey-11);
    --color-text-post-creator-character: var(--color-hinted-grey-15);

    --color-text-footer: var(--color-hinted-grey-5);
    --color-text-footer-icon: var(--color-hinted-grey-8);
    --color-text-footer-icon-hover: var(--color-purple);
    --color-text-footer-icon-active: var(--color-purple-dark);
    --color-text-pagination-hover: var(--color-purple);
    --color-text-pagination-active: var(--color-purple);

    --color-text-input-label: var(--color-hinted-grey-10);
    --color-text-input-label-error: var(--color-error);
    --color-text-input-error-message: var(--color-error);
    --color-border-input-error: var(--color-error);

    --color-text-username: var(--color-hinted-grey-11);

    --color-fill-post-like: var(--color-purple-dark);

    --color-background-search-sidebar: #fff;
    --color-border-search-sidebar: var(--color-hinted-grey-2);
    --color-background-search-sidebar-filter: transparent;
    --color-background-search-sidebar-filter-active: transparent;
    --color-border-search-sidebar-filter: var(--color-hinted-grey-2);
    --color-text-search-sidebar-filter-icon: #97a1bb;
    --color-background-search-sidebar-filter-icon: #f2f4f9;

    --color-text-privacy-sub-head: var(--color-hinted-grey-11);
    --color-background-privacy-ccpa: var(--color-hinted-grey-2);
}

/* Dark Theme */
 :root[data-theme="dark"] {
    /* **DARK** THEME DEFINITIONS */
    --color-background-body: var(--color-hinted-grey-17);
    --color-background-accent: var(--color-purple);
    --color-background-accent-alt: var(--color-purple-dark);
    --color-background-accent-secondary: var(--color-blue);
    --color-background-accent-secondary-alt: var(--color-blue-dark);
    --color-background-image-slider-pagination-bullet: var(--color-purple);
    --color-background-button-primary: transparent;    
    --color-background-button-primary-hover: var(--color-halloween);    
    --color-background-button-primary-active: var(--color-halloween-dark);    
    --color-background-button-primary-locked: transparent;    
    --color-text-button-disabled: #b1b1b1; 
    --color-background-button-disabled: #2d2e33; 
    --color-text-button-primary: var(--color-halloween);    
    --color-text-button-primary-hover: var(--color-white);    
    --color-text-button-primary-active: var(--color-white);    
    --color-text-button-primary-locked: #999999;    
    --color-border-button-primary: var(--color-halloween);
    --color-border-button-primary-locked: #e5e5e5;
    
    --color-background-input: rgb(51, 52, 57);
    --color-background-input-active: var(--color-hinted-grey-16);
    --color-border-input: none;
    --color-border-input-focus: none;
    --color-text-input: #fff;

    --color-background-toggle: #72767d;
    --color-background-toggle-checked: var(--color-blue-alt);
    --color-background-toggle-focus: var(--color-blue-alt);
    --color-background-toggle-handle: var(--color-white);

    --color-background-character-table-head: #131313;
    --color-text-character-table-head: #fff;
    --color-background-character-table-row: var(--color-hinted-grey-16);
    --color-background-character-table-row-hover: rgb(51, 52, 57);
    --color-text-character-table: var(--color-hinted-grey-3);

    --color-background-subsection-header: var(--color-hinted-grey-16);
    --color-text-subsection-header: #fff;

    --color-background-grid-post-image: var(--color-hinted-grey-10);

    --color-background-top-nav: var(--color-hinted-grey-16);
    --color-border-sidebar: var(--color-hinted-grey-18);
    --color-background-sidebar: var(--color-hinted-grey-16);
    --color-background-sidebar-item-selected: var(--color-hinted-grey-6);
    --color-background-sidebar-item-hover: #7668cb;
    --color-background-sidebar-item-active: #4e4582;

    --color-background-footer: var(--color-hinted-grey-9);

    --color-background-dropdown: var(--color-hinted-grey-16);
    --color-background-dropdown-notification-hover: var(--color-hinted-grey-17);
    --color-background-dropdown-notification-unread: #3a345f;
    --color-background-dropdown-header: var(--color-hinted-grey-16);
    --color-background-dropdown-notification-button-hover: var(--color-hinted-grey-17);
    --color-background-dropdown-divider: #383a40;
    --color-text-dropdown-primary: #fff;
    --color-text-dropdown-secondary: var(--color-hinted-grey-4);
    --color-text-dropdown-header: #fff;
    --color-text-dropdown-notification-button: var(--color-hinted-grey-3);
    --color-border-dropdown-header: var(--color-hinted-grey-17);
    
    --color-background-upload-image-action: var(--color-hinted-grey-16);
    --color-background-upload-image-action-hover: #52545f;
    --color-background-upload-image-preview: var(--color-hinted-grey-16);
    --color-background-upload-image-preview-action: var(--color-hinted-grey-16);
    --color-background-upload-image-preview-action-hover: #52545f;

    --color-background-dropzone: #232427;
    --color-background-dropzone-focus: var(--color-blue-alt);
    --color-border-dropzone: #131313;
    --color-text-dropzone: var(--color-hinted-grey-3);

    --color-background-post-image: var(--color-hinted-grey-16);
    --color-border-post-image: var(--color-hinted-grey-16);
    --color-background-post-image-preview: var(--color-hinted-grey-16);
    --color-border-post-image-preview: var(--color-hinted-grey-16);

    --color-border-post-description: #505050;

    --color-text-post-tags: var(--color-hinted-grey-20);

    --color-background-post-sell-price: #e5ffdc;
    --color-text-post-sell-price: var(--color-hinted-grey-5);

    --color-background-post-comment: var(--color-hinted-grey-16);
    --color-text-post-comment-time: var(--color-hinted-grey-20);

    --color-border-top-nav: var(--color-hinted-grey-19);
    --color-border-footer: var(--color-hinted-grey-19);

    --color-border-grid-post-image: var(--color-hinted-grey-16);
    --color-border-grid-post-image-hover: var(--color-purple);

    --color-text-body: var(--color-hinted-grey-1);
    --color-text-top-nav: var(--color-hinted-grey-1);
    --color-text-top-nav-hover: #7668cb;

    --color-text-sidebar: #ffffff;

    --color-text-section-head: #fff;
    --color-text-section-sub-head: var(--color-hinted-grey-11);
    --color-text-section-sub-section: #fff;

    --color-text-post-creator-author: #fff;
    --color-text-post-creator-character: #fff;

    --color-text-footer: var(--color-hinted-grey-20);
    --color-text-footer-icon: var(--color-hinted-grey-20);
    --color-text-footer-icon-hover: var(--color-purple);
    --color-text-footer-icon-active: var(--color-purple-dark);
    --color-text-pagination-hover: var(--color-purple);
    --color-text-pagination-active: var(--color-purple);

    --color-text-input-label: #fff;
    --color-text-input-label-error: var(--color-error);
    --color-text-input-error-message: var(--color-error);
    --color-border-input-error: var(--color-error);

    --color-text-username: #ffffff;

    --color-fill-post-like: var(--color-purple);

    --color-background-search-sidebar: #323338;
    --color-border-search-sidebar: #464749;
    --color-background-search-sidebar-filter: transparent;
    --color-background-search-sidebar-filter-active: #2d2e33;
    --color-border-search-sidebar-filter: #464749;
    --color-text-search-sidebar-filter-icon: #fff;
    --color-background-search-sidebar-filter-icon: #4d5056;

    --color-text-privacy-sub-head: #ffffff;
    --color-background-privacy-ccpa: var(--color-hinted-grey-16);
}

* {
    box-sizing: border-box;
    margin: 0;
}

*:before,
*:after {
 box-sizing: border-box;
}

body {
    background: var(--color-background-body);
    color: var(--color-text-body);
    /* font-family: 'Segoe UI', 'Lato', sans-serif; */
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: underline;
}

input[type=text], input[type=password], input[type=number], input[type=email] {
    height: 35px;
    display: inline-block;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 20px;
}

input[type=text]:focus, input[type=password]:focus, input[type=number]:focus, input[type=email]:focus {
    outline: none;
}

select {
    height: 35px;
    max-width: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    border: 0 none;
    padding: 0 10px;
    color: var(--color-text-input);
    vertical-align: middle;
    background: var(--color-background-input);
    border: 1px solid var(--color-border-input);
    transition: .2s ease-in-out;
    transition-property: color,background-color,border;
    border-radius: 5px;
    box-shadow: inset 0 1px 0 rgba(225,228,232,.2);
}

select:focus {
    outline: none;
    background-color: var(--color-background-input);
    /* color: #666; */
    /* border-color: #1e87f0; */
    border-color: var(--color-border-input-focus);
}

.submit {
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: .1s ease-in-out;
    transition-property: color,background-color,border-color;
    background-color: var(--color-background-button-primary);
    color: var(--color-text-button-primary);
    border: 1px solid var(--color-border-button-primary);
    border-radius: 5px;
}

.submit:hover {
    background: var(--color-background-button-primary-hover);
    color: var(--color-text-button-primary-hover);
}

.submit:focus {
    background: var(--color-background-button-primary-active);
    color: var(--color-text-button-primary-active);
    outline: none;
}

.submit:disabled {
    background-color: var(--color-background-button-primary-locked);
    color: var(--color-text-button-primary-locked);
    border-color: var(--color-border-button-primary-locked);
}

.pagination {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 3rem;
    padding: 0.2rem;
    list-style: none;
    justify-content: center;
    color: #999;
}

.pagination li:first-child {
    padding-left: 0px;
}

.pagination li {
    flex: none;
    padding-left: 20px;
    position: relative;
}

.pagination li.disabled {
    display: none;
}

.pagination>*>* {
    display: block;
    transition: color .1s ease-in-out;
}

.pagination>*>*:hover, .pagination>*>*:focus {
    color: #666;
    text-decoration: none;
    outline: none;
}   

.pagination a {
    cursor: pointer;
}

.pagination a:hover {
    color: var(--color-text-pagination-hover);
}

.pagination > li.active {
    color: var(--color-text-pagination-active);
}

.pagination > li.active a {
    font-size: 1.4rem;
    font-weight: 700;
    /* border-bottom: 1px solid var(--color-text-pagination-active); */
}

.Toastify__toast {
    border-radius: 5px;
    /* color: #1a1a1a; */
    text-align: center;
}

.Toastify__progress-bar {
    /* background: #1a1a1a; */
}

/* SCROLL BAR DARK MODE */
/* https://stackoverflow.com/questions/47576815/how-to-add-arrows-with-webkit-scrollbar-button */

 :root[data-theme="dark"] ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

 :root[data-theme="dark"] ::-webkit-scrollbar-corner,
 :root[data-theme="dark"] ::-webkit-scrollbar-track {
    background-color: rgb(64, 64, 64);
}

 :root[data-theme="dark"] ::-webkit-scrollbar-thumb {
    background-color: rgb(96, 96, 96);
    background-clip: padding-box;
    border: 2px solid transparent;
}

:root[data-theme="dark"] ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(112, 112, 112);
}

:root[data-theme="dark"] ::-webkit-scrollbar-thumb:active {
    background-color: rgb(128, 128, 128);
}

/* Buttons */
:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button {
    background-color: rgb(64, 64, 64);

    display: block;
    background-size: 10px;
    background-repeat: no-repeat;
}

/* Up */
:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 12px;
    width: 16px;
    background-position: center 4px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 12px;
    width: 16px;
    background-position: center 2px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:decrement {
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");

}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:increment {
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
}
