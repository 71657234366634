.login-form {
    margin: 0 auto;
    max-width: 500px;
}

.login-form .field {
    margin-bottom: 1rem;
}

.login-form .field input {
    width: 100%;
}

.login-form .login-button {
    display: flex;
    justify-content: center;
}

.login-form .login-button button {
    width: 100%;
}

.login-form .recaptcha {
    display: flex;
    justify-content: center;
}

.login-form .reset-password, .login-form .reset-password:hover {
    display: flex;
    margin-top: 5px;
    font-size: 0.9rem;
    color: #9658fe;
}

.login-form .reset-password:active {
    color: #7e4ad6;
}