.post-container .post-comments {
    margin-top: 3rem;
}

.post-container .post-comments .new-comment {
    margin: 1.4rem 0 2.4rem 0;
}

.post-container .post-comments .new-comment .comment-form {
    display: flex;
    flex-direction: column;
}

@media (min-width: 760px) {
    .post-container .post-comments .new-comment .comment-form {
        flex-direction: row;
    }
}

.post-container .post-comments .new-comment .comment-form .input-area {
    display: flex;
    align-items: center;
    flex: 1;
}

.post-container .post-comments .new-comment .comment-form .submit-area {
    display: flex;
    flex: 1;
    margin-top: 1rem;
}

@media (min-width: 760px) {
    .post-container .post-comments .new-comment .comment-form .submit-area {
        width: auto;
        margin: 0;
        flex: none;
    }
}

.post-container .post-comments .new-comment .comment-form input {
    display: flex;
    flex: 1 1 0%;
    width: 100%;
    font-weight: 500;
}

.post-container .post-comments .new-comment .comment-form .comment-button {
    width: 100%;
}


@media (min-width: 760px) {
    .post-container .post-comments .new-comment .comment-form .comment-button {
        margin-left: .5rem;
        width: auto;
    }
}

@media (min-width: 760px) {
    .post-container .post-comments .new-comment .buttons {
        display: flex;
        justify-content: flex-end;
        margin-bottom: .5rem;
    }
}

@media (max-width: 760px) {
    .post-container .post-comments .new-comment .buttons button {
        margin-top: .5rem;
        width: 100%;
    }
}


.post-container .post-comments .new-comment .avatar-container {
    margin-right: .7rem;
}

.post-container .post-comments .new-comment .avatar {
    border-radius: 3px;
    max-width: 40px;
    max-height: 40px;
    display: block;
}

/*
    New Post
*/

.new-post-form {
}

.new-post-form .field {
    margin-bottom: 1rem;
}

.new-post-form .react-tagsinput {
    background-color: transparent;
    border: 0px;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
    padding: 0;
}

.new-post-form .react-tagsinput input.react-tagsinput-input {
    display: inline-flex;
    font-family: Inter, sans-serif;
    color: var(--color-text-input);
    font-weight: 500;
    background: var(--color-background-input);
    font-size: 1rem;
    border: 0px;
    box-shadow: none;
    transition: background 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    margin-bottom: 5px;
    margin-right: 5px;
    width: auto;
}

.new-post-form .react-tagsinput input.react-tagsinput-input::placeholder {
    color: #a9a9a9;
}

.new-post-form .react-tagsinput input.react-tagsinput-input:focus, .new-post-form .react-tagsinput input.react-tagsinput-input:active {
    background: var(--color-background-input-active);
}

.new-post-form .react-tagsinput .react-tagsinput-tag {
    font-family: 'Inter', sans-serif;
    padding: 0.5rem;
    border-radius: 3px;
    font-size: 1rem;
    border: 0px;
}

.new-post-form .field .error-message {
    margin: 10px 0;
    font-size: .8rem;
    color: #f0506e;
}

.new-post-form .new-post-button {
    display: flex;
    justify-content: center;
}

.new-post-form .field .choose-category {
    display: flex;
    align-content: center;
    flex-direction: column;
}

@media (min-width: 425px) {
    .new-post-form .field .choose-category {
        flex-direction: row;
    }
}

.new-post-form .field .choose-category .category {
    flex: 1 1 0;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
    cursor: pointer;
    color: #97a1bb;
    overflow: hidden;
    position: relative;
    height: 200px;
}

@media (min-width: 425px) {
    .new-post-form .field .choose-category .category {
        height: 416px;
    }
}


@media (min-width: 780px) {
    .new-post-form .field .choose-category .category {
        height: 560px;
    }
}

.new-post-form .field .choose-category .category .bg {
    z-index: -1;
    height: 200px;
    width: 1680px;
    /* width: 5076px; */
    animation: slide 20s linear infinite;
    /* animation: slide 60s linear infinite; */
}

@media (min-width: 425px) {
    .new-post-form .field .choose-category .category .bg {
        height: 416px;
    }
}


@media (min-width: 780px) {
    .new-post-form .field .choose-category .category .bg {
        height: 560px;
    }
}

.new-post-form .field .choose-category .category.symbol .bg {
    background: url(../../static/images/symbol-pod-bg.png) repeat-x;
    background-size: contain;
}

.new-post-form .field .choose-category .category.clothing .bg {
    background: url(../../static/images/clothing-pod-bg.png) repeat-x;
    background-size: contain;
}

.new-post-form .field .choose-category .category.vehicle .bg {
    background: url(../../static/images/vehicle-pod-bg-2.png) repeat-x;
    background-size: contain;
}

.new-post-form .field .choose-category .category.character .bg {
    background: url(../../static/images/character-customization-pod-bg-2.png) repeat-x;
    background-size: contain;
}

.new-post-form .field .choose-category .category .icon-container {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new-post-form .field .choose-category .category .icon-container .icon {
    color: #fff;
}

.new-post-form .field .choose-category .category .icon-container .category-name {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    color: #fff;
    opacity: 0;
    height: 0;
    transition: all .2s;
    text-transform: uppercase;
}

.new-post-form .field .choose-category .category .icon-container:hover .category-name {
    opacity: 1;
}

.new-post-form .field.post-captcha {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}

/* https://css-tricks.com/books/fundamental-css-tactics/infinite-scrolling-background-image/ */

@keyframes slide{
  0%{
    transform: translate3d(0, 0, 0);
  }
  100%{
    transform: translate3d(-560px, 0, 0);
    /* transform: translate3d(-1692, 0, 0); */
  }
}

.new-post-form .step-3 {
    opacity: 0;
    height: 0px;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
}

.new-post-form .step-3.show {
    height: auto;
    opacity: 1;
}