/* .croppie-container {
	border: 1px solid #585858;
} */

.croppie-container .cr-viewport {
	border: 2px dashed #d5dee8;
	max-width: 100%;
	/* box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, .2); */
}

/* .croppie-container .cr-slider-wrap {
	display: none
} */

.image-preview-list {
	display: flex;
}