.notification-badge {
    display: inline-block;
    position: absolute;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: rgb(255, 255, 255);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: rgb(150, 88, 254);
    border-radius: 10px;
    top: -11px;
    right: -16px;
}