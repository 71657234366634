@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
.login-form {
    margin: 0 auto;
    max-width: 500px;
}

.login-form .field {
    margin-bottom: 1rem;
}

.login-form .field input {
    width: 100%;
}

.login-form .login-button {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.login-form .login-button button {
    width: 100%;
}

.login-form .recaptcha {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.login-form .reset-password, .login-form .reset-password:hover {
    display: -webkit-flex;
    display: flex;
    margin-top: 5px;
    font-size: 0.9rem;
    color: #9658fe;
}

.login-form .reset-password:active {
    color: #7e4ad6;
}
.password-strength-meter {
  text-align: left;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 250px;
  height: 8px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.password-strength-meter-label {
  font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
  background-color: #F25F5C;
}
.strength-Fair::-webkit-progress-value {
  background-color: #FFE066;
}
.strength-Good::-webkit-progress-value {
  background-color: #247BA0;
}
.strength-Strong::-webkit-progress-value {
  background-color: #70C1B3;
}
/* 500 */

.register-form {
    margin: 0 auto;
    max-width: 500px;
}

.register-form .field {
    margin-bottom: 1rem;
}

.register-form .field input {
    width: 100%;
}

.register-form .field .error-message {
    margin: 10px 0;
    font-size: .8rem;
    color: var(--color-text-input-error-message);
}

.register-form .register-button {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0.5rem 0;
}

.register-form .register-button .submit {
    width: 100%;
}
.avatar-crop-options {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.avatar-crop-options .option {
    background: #e8e8e8;
    border-radius: 3px;
    cursor: pointer;
    padding: .5rem;
    -webkit-transition: .3s background;
    transition: .3s background;
}

.avatar-crop-options .option:hover {
    background: #cecece;
}

.avatar-crop-options .option img {
    display: block;
}

.saved-avatar-preview, .upload-avatar-placeholder {
    width: 140px;
    height: 140px;
    margin: 0 auto .5em auto;
}

.saved-avatar-preview .avatar, .upload-avatar-placeholder .avatar {
    width: 100%;
    height: auto;
    border-radius: 3px;
    border: 3px solid #fafafa;
}

.upload-avatar-placeholder .avatar {
    background: #cecece;
    height: 100%;
}

.upload-avatar-placeholder-two {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 140px;
    height: 140px;
    margin: 0 auto;
    background: #e8e8e8;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.upload-avatar-placeholder-two .avatar {
    border-radius: 3px;
    width: 100%;
}

.upload-avatar-placeholder-two .edit {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background: #000;
    color: #ffffff;
    opacity: 0;
    -webkit-transition: opacity 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out;
}

.upload-avatar-placeholder-two:hover .edit {
    opacity: 0.7;
}
.info {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    font-size: 2rem;
    /* padding: 0.5rem 0; */
}

@media (min-width: 768px) {
    .info {
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
}   
 

.info .avatar {
    width: 140px;
    height: 140px;
    position: relative;
}

@media (min-width: 768px) {
    .info .avatar {
        margin-top: -35px;
    }
}  

.info .avatar-image {
    width: 100%;
    height: auto;
    border-radius: 3px;
    /* border: 1px solid var(--color-background-body); */
    background: var(--color-background-body);
    box-shadow: 0px 0px 5px 0px #000;
}

.info .avatar .badge {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    bottom: 2px;
    right: 2px;
    background: rgb(14 14 14 / 90%);
    padding: 6px;
    border-radius: 10px;
}

.info .details {
    font-family: 'Roboto', sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 1rem;
}

@media (min-width: 768px) {
    .info .details {
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
} 

.info .details .username {
    font-size: 1em;
    font-weight: 500;
    color: var(--color-text-username);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0.4rem 0 0.2rem 0;
}

@media (min-width: 768px) {
    .info .details .username {
        padding: 0;
    }
} 

.info.banned .details .username {
    text-decoration: line-through;
}

.info .details .username .verified {
    margin-left: 0.5rem;
}

.info .details .user-group {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: .44em;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.2rem 0;
}

.info .details .user-group .badge {
    display: -webkit-flex;
    display: flex;
    margin-right: 0.2rem;
}

.info .details .join-date {
    font-size: .44em;
    font-weight: 600;
    color: #545454;
    color: #a2a2a2;
    display: -webkit-flex;
    display: flex;
    text-transform: uppercase;
    padding: 0.2rem 0;
}

@media (min-width: 768px) {
    .info .details .join-date {
        /* padding: 0; */
    }
}

.info .details .metrics {
    font-size: .48em;
    font-weight: 600;
    display: -webkit-flex;
    display: flex;
    text-transform: uppercase;
    padding: 0.2rem 0;
}

.info .details .metrics .follow-type {
    font-weight: 500;
    color: #a2a2a2;
    color: #bbbbbb;
}

.info .details .metrics .following {
    margin-right: 1rem;
}

.info .details .social-media {
    font-size: .4em;
    font-weight: 600;
    color: #545454;
    color: #a2a2a2;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-transform: uppercase;
    padding: 0.4rem 0;
}

.info .details .social-media .site {
    display: -webkit-flex;
    display: flex;
    margin-right: 0.8rem;
}

.info .details .social-media .site:last-child {
    margin-right: 0;
}

.info .details .social-media .site .icon {
    -webkit-transition: color 0.1s ease-in-out;
    transition: color 0.1s ease-in-out;
}

.info .details .social-media .site .icon.gamersfirst {
    color: #a2a2a2;
}

.info .details .social-media .site .icon.gamersfirst:hover {
    color: #1b8eca;
}

.info .details .social-media .site .icon.twitter:hover {
    color: #00a4ee;
}

.info .details .social-media .site .icon.twitch:hover {
    color: #9147ff;
}

.info .details .social-media .site .icon.youtube:hover {
    color: #ff0000;
}

.info .details .user-dropdown {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1em;
    font-weight: 300;
}

.edit-user-form, .edit-user-characters-form {
    margin: 0 auto;
    /* max-width: 500px; */
}

.edit-user-form .field, .edit-user-characters-form .field {
    margin-bottom: 1rem;
}

.edit-user-form .field input, .edit-user-characters-form .field input {
    width: 100%;
}

.edit-user-characters-form .claim-characters-form {
    max-width: 500px;
    margin: 0 auto;
}

.edit-user-form .field .error-message, .edit-user-characters-form .field .error-message {
    margin: 10px 0;
    font-size: .8rem;
    color: #f0506e;
}

.edit-user-form .edit-user-button, .edit-user-characters-form .edit-user-button {
    padding: 1rem 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

/* 

TABULAR NAV

*/

/* .tabular-nav a {
  text-decoration: none;
} */

.tabular-nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    list-style: none;
    position: relative;
    margin: 1rem 0;
    padding: 0 3px;
    -webkit-justify-content: center;
            justify-content: center;
}

.tabular-nav:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #e5e5e5;
}

.tabular-nav>* {
    -webkit-flex: none;
            flex: none;
    position: relative;
}

.tabular-nav>.active>span, .tabular-nav>.active>span:hover, .tabular-nav>.active>span:focus {
    color: #222638;
    border-color: #9658fe;
}
.tabular-nav>*>span {
    font-family: 'Khula', sans-serif;
    font-weight: 600;
    display: -webkit-flex;
    display: flex;
    text-align: center;
    padding: 9px 20px;
    color: #999;
    border-bottom: 2px solid transparent;
    font-size: 16px;
    text-transform: uppercase;
    -webkit-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
    line-height: 20px;
    cursor: pointer;
}

.tabular-nav>*>span:hover, .switcher-nav>*>span:focus {
    color: #666;
}

.category-icon {
    width: 30px;
    height: 30px;
    display: -webkit-inline-flex;
    display: inline-flex;
}

.category-text {
    display: none;
}

@media (min-width: 760px) {
    .tabular-nav {
        /* justify-content: left; */
    }

    .tabular-nav .tab {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
    }

    .category-icon {
        width: 25px;
        height: 25px;
        margin-right: 0.3rem;
    }

    .category-text {
        display: -webkit-inline-flex;
        display: inline-flex;
        margin-top: 3px;
        /* height: 18px; */
    }
    
}

/* New Nav */

.profile-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: #151517;
    /* width: 100%;
    height: 52px;
    margin: 2rem 0; */
    margin: 1.5rem 0 2rem 0;
}

@media(min-width: 760px) {
    .profile-menu {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        width: 100%;
        height: 52px;
        background: #151517;
        margin: 2rem 0;
    }
}

.profile-menu .item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem;
    font-weight: 700;
    font-size: 0.8rem;
    text-transform: uppercase;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    -webkit-transition: border 0.1s ease-in-out;
    transition: border 0.1s ease-in-out;
    letter-spacing: 1px;
    height: 100%;
}

@media(min-width: 760px) {
    .profile-menu .item {
        padding: 0 1rem;
    }
}

.profile-menu .item:hover {
    border-bottom: 3px solid #c7c7da;
}

.profile-menu .item.active {
    border-bottom: 3px solid #7668cb;
}

.profile-menu .right-menu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    border-top: 0.5rem solid #27282c;
}

@media(min-width: 760px) {
    .profile-menu .right-menu {
        -webkit-flex-direction: row;
                flex-direction: row;
        margin-left: auto;
        border-top: 0px;
        border-left: 5px solid #27282c;
    }
}

.profile-menu .right-menu .dropdown_trigger,
.profile-menu .right-menu .options {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;;
}

.profile-menu .right-menu .follow,
.profile-menu .right-menu .message,
.profile-menu .right-menu .options {
    background: transparent;
    border: none;
    -webkit-transition: background .1s ease-in-out;
    transition: background .1s ease-in-out;
}

.profile-menu .right-menu .follow {
    color: #ad7cff;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

@media(min-width: 760px) {
    .profile-menu .right-menu .follow,
    .profile-menu .right-menu .message {
        padding: 0 2rem;
    }
}

.profile-menu .right-menu .follow:hover,
.profile-menu .right-menu .message:hover,
.profile-menu .right-menu .options:hover {
    background: #0c0c0c;
}

.profile-menu .right-menu .follow:active,
.profile-menu .right-menu .follow.following:active,
.profile-menu .right-menu .message:active,
.profile-menu .right-menu .options:active {
    background: #000;
}

.profile-menu .right-menu .follow.following {
    background: #0c0c0c;
}

@media(min-width: 760px) {
    .profile-menu .right-menu .options {
        border-left: 1px solid #27282c;
    }
}
.homepage-cover {
    background: url(/static/media/art-2.324f9a5f.png);
    /* background-attachment: fixed; */
    background-size: cover;
    background-position: center;
}
.dropdown-item {
    display: block;
    color: var(--color-text-dropdown-primary);
    margin-bottom: 4px;
    white-space: nowrap;   
    cursor: pointer;
}

.dropdown-item, .dropdown-item a, .dropdown-item a:hover {
    text-decoration: none;
}

.dropdown-item:hover {
    color: var(--color-text-dropdown-secondary);
    text-decoration: inherit;
}

.dropdown-item-divider {
    margin: 5px 0;
    border-top: 1px solid var(--color-background-dropdown-divider);
}
.post-container .post-comments {
    margin-top: 3rem;
}

.post-container .post-comments .new-comment {
    margin: 1.4rem 0 2.4rem 0;
}

.post-container .post-comments .new-comment .comment-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

@media (min-width: 760px) {
    .post-container .post-comments .new-comment .comment-form {
        -webkit-flex-direction: row;
                flex-direction: row;
    }
}

.post-container .post-comments .new-comment .comment-form .input-area {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.post-container .post-comments .new-comment .comment-form .submit-area {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-top: 1rem;
}

@media (min-width: 760px) {
    .post-container .post-comments .new-comment .comment-form .submit-area {
        width: auto;
        margin: 0;
        -webkit-flex: none;
                flex: none;
    }
}

.post-container .post-comments .new-comment .comment-form input {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    width: 100%;
    font-weight: 500;
}

.post-container .post-comments .new-comment .comment-form .comment-button {
    width: 100%;
}


@media (min-width: 760px) {
    .post-container .post-comments .new-comment .comment-form .comment-button {
        margin-left: .5rem;
        width: auto;
    }
}

@media (min-width: 760px) {
    .post-container .post-comments .new-comment .buttons {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        margin-bottom: .5rem;
    }
}

@media (max-width: 760px) {
    .post-container .post-comments .new-comment .buttons button {
        margin-top: .5rem;
        width: 100%;
    }
}


.post-container .post-comments .new-comment .avatar-container {
    margin-right: .7rem;
}

.post-container .post-comments .new-comment .avatar {
    border-radius: 3px;
    max-width: 40px;
    max-height: 40px;
    display: block;
}

/*
    New Post
*/

.new-post-form {
}

.new-post-form .field {
    margin-bottom: 1rem;
}

.new-post-form .react-tagsinput {
    background-color: transparent;
    border: 0px;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
    padding: 0;
}

.new-post-form .react-tagsinput input.react-tagsinput-input {
    display: -webkit-inline-flex;
    display: inline-flex;
    font-family: Inter, sans-serif;
    color: var(--color-text-input);
    font-weight: 500;
    background: var(--color-background-input);
    font-size: 1rem;
    border: 0px;
    box-shadow: none;
    -webkit-transition: background 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s;
    transition: background 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    margin-bottom: 5px;
    margin-right: 5px;
    width: auto;
}

.new-post-form .react-tagsinput input.react-tagsinput-input::-webkit-input-placeholder {
    color: #a9a9a9;
}

.new-post-form .react-tagsinput input.react-tagsinput-input::-ms-input-placeholder {
    color: #a9a9a9;
}

.new-post-form .react-tagsinput input.react-tagsinput-input::placeholder {
    color: #a9a9a9;
}

.new-post-form .react-tagsinput input.react-tagsinput-input:focus, .new-post-form .react-tagsinput input.react-tagsinput-input:active {
    background: var(--color-background-input-active);
}

.new-post-form .react-tagsinput .react-tagsinput-tag {
    font-family: 'Inter', sans-serif;
    padding: 0.5rem;
    border-radius: 3px;
    font-size: 1rem;
    border: 0px;
}

.new-post-form .field .error-message {
    margin: 10px 0;
    font-size: .8rem;
    color: #f0506e;
}

.new-post-form .new-post-button {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.new-post-form .field .choose-category {
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

@media (min-width: 425px) {
    .new-post-form .field .choose-category {
        -webkit-flex-direction: row;
                flex-direction: row;
    }
}

.new-post-form .field .choose-category .category {
    -webkit-flex: 1 1;
            flex: 1 1;
    border-radius: 5px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0.5rem;
    cursor: pointer;
    color: #97a1bb;
    overflow: hidden;
    position: relative;
    height: 200px;
}

@media (min-width: 425px) {
    .new-post-form .field .choose-category .category {
        height: 416px;
    }
}


@media (min-width: 780px) {
    .new-post-form .field .choose-category .category {
        height: 560px;
    }
}

.new-post-form .field .choose-category .category .bg {
    z-index: -1;
    height: 200px;
    width: 1680px;
    /* width: 5076px; */
    -webkit-animation: slide 20s linear infinite;
            animation: slide 20s linear infinite;
    /* animation: slide 60s linear infinite; */
}

@media (min-width: 425px) {
    .new-post-form .field .choose-category .category .bg {
        height: 416px;
    }
}


@media (min-width: 780px) {
    .new-post-form .field .choose-category .category .bg {
        height: 560px;
    }
}

.new-post-form .field .choose-category .category.symbol .bg {
    background: url(/static/media/symbol-pod-bg.8b6a634f.png) repeat-x;
    background-size: contain;
}

.new-post-form .field .choose-category .category.clothing .bg {
    background: url(/static/media/clothing-pod-bg.eab10c75.png) repeat-x;
    background-size: contain;
}

.new-post-form .field .choose-category .category.vehicle .bg {
    background: url(/static/media/vehicle-pod-bg-2.d7ed3a2c.png) repeat-x;
    background-size: contain;
}

.new-post-form .field .choose-category .category.character .bg {
    background: url(/static/media/character-customization-pod-bg-2.5b86c405.png) repeat-x;
    background-size: contain;
}

.new-post-form .field .choose-category .category .icon-container {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.new-post-form .field .choose-category .category .icon-container .icon {
    color: #fff;
}

.new-post-form .field .choose-category .category .icon-container .category-name {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    color: #fff;
    opacity: 0;
    height: 0;
    -webkit-transition: all .2s;
    transition: all .2s;
    text-transform: uppercase;
}

.new-post-form .field .choose-category .category .icon-container:hover .category-name {
    opacity: 1;
}

.new-post-form .field.post-captcha {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

/* https://css-tricks.com/books/fundamental-css-tactics/infinite-scrolling-background-image/ */

@-webkit-keyframes slide{
  0%{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  100%{
    -webkit-transform: translate3d(-560px, 0, 0);
            transform: translate3d(-560px, 0, 0);
    /* transform: translate3d(-1692, 0, 0); */
  }
}

@keyframes slide{
  0%{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  100%{
    -webkit-transform: translate3d(-560px, 0, 0);
            transform: translate3d(-560px, 0, 0);
    /* transform: translate3d(-1692, 0, 0); */
  }
}

.new-post-form .step-3 {
    opacity: 0;
    height: 0px;
    overflow: hidden;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.new-post-form .step-3.show {
    height: auto;
    opacity: 1;
}
.game-item-search {
    /* display: flex; */
    /* flex-direction: column; */
    position: relative;
    margin-bottom: 1em;
}
.image-selector {
    margin-right: 4px;
    position: relative;
}

.image-selector .image-action-icon {
    cursor: pointer;
}

.image-selector .upload-image-preview {
    display: block;
    width: 86px;
    height: 144px;
    min-height: 144px;
    background-color: #d8dde3;
    border: 1px solid #c3c8ce;
}

.image-selector .remove-image {
    position: absolute;
    top: 2px;
    right: 2px;
}

.image-selector .crop-image {
    position: absolute;
    bottom: 2px;
    left: 2px;
}

.selected {
    /* border: 1px solid #1e87f0; */
    border: 1px solid #9658fe;
}

.image-selector .save-image {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffffc7;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
}

/* .image-selector .save-icon {

} */

.image-selector .position-controls {
    display: -webkit-flex;
    display: flex;
}

.image-selector .position-controls .left {
    display: -webkit-inline-flex;
    display: inline-flex;
}

.image-selector .position-controls .right {
    display: -webkit-inline-flex;
    display: inline-flex;
    margin-left: auto;
}
/* .croppie-container {
	border: 1px solid #585858;
} */

.croppie-container .cr-viewport {
	border: 2px dashed #d5dee8;
	max-width: 100%;
	/* box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, .2); */
}

/* .croppie-container .cr-slider-wrap {
	display: none
} */

.image-preview-list {
	display: -webkit-flex;
	display: flex;
}
.dropzone-container {
  margin-top: 2rem;
}

.dropzone {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 3rem;
  border-width: 2px;
  border-radius: 5px;
  border-color: var(--color-border-dropzone);
  border-style: dashed;
  background-color: var(--color-background-dropzone);
  color: var(--color-text-dropzone);
  outline: none;
  -webkit-transition: border .24s ease-in-out;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: var(--color-background-dropzone-focus);
}

.dropzone.disabled {
  opacity: 0.6;
}
.image-selector {
    position: relative;
    margin: 4px;
}

.image-selector .preview-controls {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.image-selector .position-controls {
    display: -webkit-flex;
    display: flex;
}

.image-selector .position-controls .left {
    display: -webkit-inline-flex;
    display: inline-flex;
}

.image-selector .position-controls .right {
    display: -webkit-inline-flex;
    display: inline-flex;
    margin-left: auto;
}
.croppie-container .cr-viewport {
	border: 1px dashed #d5dee8;
	max-width: 100%;
	/* box-shadow: 0 0 2000px 2000px rgba(0, 0, 0, .2); */
}

.image-preview-list {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
}

.post-crop-options {
    display: -webkit-flex;
    display: flex;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	margin: 2rem 0;
}

.post-crop-options .option {
    background: var(--color-background-upload-image-action);
    border-radius: 3px;
    cursor: pointer;
    padding: .5rem;
    -webkit-transition: .3s background;
    transition: .3s background;
}

.post-crop-options .option:hover {
    background: var(--color-background-upload-image-action-hover);
}

.post-crop-options .option svg, .post-crop-options .option img {
    display: block;
}
.search-page {
    height: 100%;
}

.search-page .search-area {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
}

@media (min-width: 780px) {
    .search-page .search-area {
        -webkit-flex-direction: row;
                flex-direction: row;
    }
}

.search-page .search-area .sidebar {
    width: 100%;
    background: var(--color-background-search-sidebar);
    /* background: #323338; */
    margin: 2rem 0;
}

@media (min-width: 780px) {
    .search-page .search-area .sidebar {
        width: 380px;
        border-right: 1px solid var(--color-border-search-sidebar);
        margin: 0px;
    }
}

.search-page .search-area .sidebar .show-search-filters {
    text-align: center;
    font-size: .9em;
    cursor: pointer;
    padding: 1em;
}

.search-page .search-area .sidebar .show-search-filters:hover {
    text-decoration: underline;
}

.search-page .search-area .sidebar .filters {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
}

@media (min-width: 780px) {
    .search-page .search-area .sidebar .filters {
        position: -webkit-sticky;
        position: sticky;
        top: 58px;
    }
}

.search-page .search-area .sidebar .filters .search-barr {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 1em;
    min-height: 50px;
    color: #3b3b3b;
}

.search-page .search-area .sidebar .filters .search-barr input {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    height: auto;
    border-radius: 5px 0 0 5px;
}

.search-page .search-area .sidebar .filters .search-barr button {
    border-radius: 0 5px 5px 0;
    border: 1px solid #e5e5e5;
    border-left: none;
    -webkit-transition-property: color, background-color, border;
    transition-property: color, background-color, border;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    cursor: pointer;
    outline: none;
}

.search-page .search-area .sidebar .filters .search-barr input:focus + button, .search-page .search-area .sidebar .filters .search-barr button:focus {
    background-color: #9658fe;
    color: #fff;
    border-color: #9658fe;
}

.search-page .search-area .sidebar .filter {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    font-size: 1rem;
    padding: .7rem;
    background: var(--color-background-search-sidebar-filter);
    border-bottom: 1px solid var(--color-border-search-sidebar-filter);
}

.search-page .search-area .sidebar .filter.active {
    background: var(--color-background-search-sidebar-filter-active);
}

/* .search-page .sidebar .filter.symbols {
    background: #cecece;
    color: #1a1a1a;
} */

.search-page .search-area .sidebar .filter .title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-weight: 600;
    font-size: 1.2em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.search-page .search-area .sidebar .filter .title .icon {
    display: -webkit-flex;
    display: flex;
    border-radius: 3px;
    background: #f2f4f9;
    padding: .6em;
    color: var(--color-text-search-sidebar-filter-icon);
    -webkit-transition: background .2s ease-out, color .2s ease-out;
    transition: background .2s ease-out, color .2s ease-out;
}

.search-page .search-area .sidebar .filter .title .icon.active {
    color: #ffffff;
}

/* .search-page .search-area .sidebar .filter.for-sale .title .icon {
    background: #2dcb73;
    color: #ffffff;
} */

.search-page .search-area .sidebar .filter .title .name {
    margin-left: 1em;
    line-height: 1em;
}

.search-page .search-area .sidebar .filter .filter-options {
    margin-top: .7em;
    padding: .7em 0;
    border-top: 1px dashed #5b5d67
}

.search-page .search-area .sidebar .filter .filter-options .option:first-child {
    margin-top: 0;
}

.search-page .search-area .sidebar .filter .filter-options .option {
    margin-top: .5rem;
}

.search-page .search-area .sidebar .filter .filter-options .option .name {
    display: block;
    margin-bottom: 5px;
    font-size: .8rem;
}

.search-page .search-area .sidebar .filter .filter-options .option input {
    width: 100%;
}

.search-page .search-area .sidebar .filter .filter-options .option .input-range {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.search-page .search-area .main-content {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.search-page .search-area .main-content .search-results {
    padding: 10px;
}

.form-input-range {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.form-input-range input {
    width: 0px;
}

.apply-filters {
    background: #222;
    color: #fafafa;
}

.search-filters-divider {
    position: relative;
    width: 100%;
    margin-bottom: 1em;
    text-align: center;
}

.search-filters-divider.link {
    cursor: pointer;
}

.search-filters-divider.link:hover h1 {
    text-decoration: underline;
}

.search-filters-divider:before {
    content: "";
    position: absolute;
    bottom: 50%;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #efefef;
}

.search-filters-divider h1 {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0 20px;
    background-color: #ffffff;
    font-size: 1em;
    font-weight: 300;
}

.search-header {
    position: relative;
    margin: 40px auto;
    text-align: center;
}

@media (min-width: 780px) {
    .search-header {
        text-align: left;
    }
}

.search-header:before {
    content: "";
    display: block;
    position: absolute;
    left: calc(50% - 35px);
    right: 0;
    bottom: -20px;
    width: 70px;
    height: 2px;
    background-color: #9658fe;
}

@media (min-width: 780px) {
    .search-header:before {
        top: 0;
        left: 0;
        bottom: 0;
        width: 2px;
        height: 100%;
    }
}

.search-header .title {
    font-size: 2em;
    font-weight: 600;
}

@media (min-width: 780px) {
    .search-header .title {
        padding-left: 1em;
        line-height: 1.8em;
    }
}
.privacy-sub-head {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-text-privacy-sub-head);
    text-transform: uppercase;
    margin: 40px 0 20px 0;
}

.privacy-p {
    margin: 10px auto;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: 1px;
}

.privacy-link, .privacy-link:hover {
    border-bottom: 2px solid #9658fe;
    text-decoration: none;
}

.privacy-ccpa {
    max-width: 1084px;
    margin: 10px auto;
    padding: 20px;
    background-color: var(--color-background-privacy-ccpa);
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: 1px;
}

.privacy-ccpa b {
    font-weight: 600;
}
.reset-password-form {
    width: 301px;
    margin: 0 auto;
}

.reset-password-form .field {
    margin-bottom: 1rem;
    max-width: 600px;
    margin: 0 auto;
}

.reset-password-form .field input[type=text], .reset-password-form .field input[type=password] {
    width: 100%;
}

.reset-password-form .field .error-message {
    margin: 10px 0;
    font-size: .8rem;
    color: #f0506e;
}

.reset-password-form .reset-password-button {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.reset-password-form .field.reset-password-captcha {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.reset-password-form .field.reset-password-button .submit {
    width: 100%;
}
.notification-badge {
    display: inline-block;
    position: absolute;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: rgb(255, 255, 255);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: rgb(150, 88, 254);
    border-radius: 10px;
    top: -11px;
    right: -16px;
}
.notification-dropdown-button {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 0 0 5px 5px;
    -webkit-transition: background 0.1s ease;
    transition: background 0.1s ease;
    padding: 1rem;

    /* background: #f7f7f7; */
    color: var(--color-text-dropdown-notification-button);
    font-weight: 600;
    border-top: 1px var(--color-border-dropdown-header) solid;
}

.notification-dropdown-button:hover {
    background: var(--color-background-dropdown-notification-button-hover);
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;

    background: var(--color-background-footer);
    border-top: 1px solid var(--color-border-footer);
    color: var(--color-text-footer);
    font-size: 16px;
}

.footer .contents {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.footer .contents .apb-dot-socials {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.footer .contents .apb-dot-socials a {
    display: -webkit-flex;
    display: flex;
}

.footer .contents .apb-dot-socials .icon {
    display: block;
    color: var(--color-text-footer-icon);
    -webkit-transition: .2s color;
    transition: .2s color;
    margin: 4px;
}

.footer .contents .apb-dot-socials .icon:hover {
    color: var(--color-text-footer-icon-hover);
}

.footer .contents .apb-dot-socials .icon:active {
    color: var(--color-text-footer-icon-active);
}

.footer .contents .copyright {
    text-align: center;
    font-size: .7em;
}

.footer .contents .privacy {
    font-size: .7em;
    margin-top: .5em;
    text-align: center;
}
.slide-pane {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: var(--color-background-sidebar);
  border-right: 1px solid var(--color-border-sidebar);
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0,0,0,0.5);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  will-change: transform;
  z-index: 99;
}
.slide-pane:focus {
  outline-style: none;
}
.slide-pane_from_right {
  margin-left: auto;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.slide-pane_from_right.content-after-open {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.slide-pane_from_right.content-before-close {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.slide-pane_from_left {
  margin-right: auto;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.slide-pane_from_left.content-after-open {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.slide-pane_from_left.content-before-close {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.slide-pane_from_bottom {
  height: 90vh;
  margin-top: 10vh;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.slide-pane_from_bottom.content-after-open {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}
.slide-pane_from_bottom.content-before-close {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0,0);
  z-index: 5;
}
.slide-pane__overlay.overlay-after-open {
  /* background-color: rgba(0,0,0,0.3); */
  background-color: rgba(6,7,13,.5);
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}
.slide-pane__overlay.overlay-before-close {
  background-color: rgba(0,0,0,0);
}
.slide-pane__header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 0 64px;
          flex: 0 0 64px;
  -webkit-align-items: center;
          align-items: center;
  background: #ebebeb;
  height: 64px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.slide-pane__title-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 32px;
  min-width: 0;
}
.slide-pane .slide-pane__title {
  font-size: 18px;
  font-weight: normal;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}
.slide-pane__close {
  margin-left: 24px;
  padding: 16px;
  opacity: 0.7;
  cursor: pointer;
}
.slide-pane__close svg {
  width: 12px;
  padding: 0;
}
.slide-pane__content {
  position: relative;
  overflow-y: auto;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
.slide-pane__subtitle {
  font-size: 12px;
  margin-top: 2px;
}

:root {
    --breakpoint-sm: 425px;
    --breakpoint-md: 760px;
    --breakpoint-lg: 1020px; 

    /* COLOR DEFINITIONS */
    --color-purple: #9658fe;
    --color-purple-dark: #6d40b9;
    --color-halloween: #ff8517;
    --color-halloween-dark: #c36e12;
    --color-blue: #1e87f0;
    --color-blue-alt: #2196f3;
    --color-blue-dark:  #1377ff;
    --color-error: #f0506e;
    --color-white: #ffffff;
    --color-hinted-grey-1: #fafafa;
    --color-hinted-grey-2: #f3f3f3;
    --color-hinted-grey-3: #e8e8e8;
    --color-hinted-grey-4: #d0d0d0;
    --color-hinted-grey-5: #3b3b3b;
    --color-hinted-grey-6: #2b2d31;
    --color-hinted-grey-7: #27282c;
    --color-hinted-grey-8: #222638;
    --color-hinted-grey-9: #1e1f23;
    --color-hinted-grey-10: #1d1d1d;
    --color-hinted-grey-11: #020202;
    --color-hinted-grey-12: #bdbdbd;
    --color-hinted-grey-13: #cecece;
    --color-hinted-grey-14: #cccccc;
    --color-hinted-grey-15: #333439;
    --color-hinted-grey-16: #1c1d20;
    --color-hinted-grey-17: #27282c;
    --color-hinted-grey-18: #464749;
    --color-hinted-grey-19: #313235;
    --color-hinted-grey-20: #a2a2a2;
    --color-brand-discord: #7289DA;
    --color-brand-discord-alt: #4E5D94;
    --color-brand-twitter: #1DA1F2;
}

:root {
    /* **LIGHT** THEME DEFINITIONS */
    --color-background-body: var(--color-white);
    --color-background-accent: var(--color-purple);
    --color-background-accent-alt: var(--color-blue);
    --color-background-accent-secondary: var(--color-purple-dark);
    --color-background-accent-secondary-alt: var(--color-blue-dark);
    --color-background-image-slider-pagination-bullet: var(--color-purple);
    --color-background-button-primary: transparent;    
    --color-background-button-primary-hover: var(--color-purple);    
    --color-background-button-primary-active: var(--color-purple-dark);    
    --color-background-button-primary-locked: transparent;
    --color-text-button-disabled: #6f6f6f; 
    --color-background-button-disabled: #e5e5e5; 
    --color-text-button-primary: var(--color-purple);    
    --color-text-button-primary-hover: var(--color-white);    
    --color-text-button-primary-active: var(--color-white);    
    --color-text-button-primary-locked: #999999;    
    --color-border-button-primary: var(--color-purple);
    --color-border-button-primary-locked: #e5e5e5;
    
    --color-background-input: #f7f7f7;
    --color-background-input-active: #e4e4e4;
    --color-border-input: var(--color-hinted-grey-3);
    --color-border-input-focus: var(--color-purple);
    --color-text-input: var(--color-hinted-grey-5);

    --color-background-toggle: var(--color-hinted-grey-14);
    --color-background-toggle-checked: var(--color-blue-alt);
    --color-background-toggle-focus: var(--color-blue-alt);
    --color-background-toggle-handle: var(--color-white);

    --color-background-character-table-head: #f7f7f7;
    --color-text-character-table-head: #1a1a1a;
    --color-background-character-table-row: transparent;
    --color-background-character-table-row-hover: #f7f7f7;
    --color-text-character-table: #313a47;

    --color-background-subsection-header: #f7f7f7;
    --color-text-subsection-header: var(--color-hinted-grey-10);

    --color-background-grid-post-image: var(--color-hinted-grey-3);

    --color-background-top-nav: var(--color-hinted-grey-1);
    --color-background-sidebar: var(--color-hinted-grey-1);
    --color-background-sidebar-item-selected: var(--color-hinted-grey-3);
    --color-background-sidebar-item-hover: #7668cb;
    --color-background-sidebar-item-active: #4e4582;

    --color-background-footer: var(--color-hinted-grey-1);
    
    --color-background-dropdown: #fff;
    --color-background-dropdown-notification-hover: #e4e4e4;
    --color-background-dropdown-notification-unread: #fcfdd0;
    --color-background-dropdown-header: #f7f7f7;
    --color-background-dropdown-notification-button-hover: #e4e4e4;
    --color-background-dropdown-divider: #e5e5e5;
    --color-text-dropdown-primary: #3c3c3c;
    --color-text-dropdown-secondary: #666;
    --color-text-dropdown-header: #1a1a1a;
    --color-text-dropdown-notification-button: #1a1a1a;
    --color-border-dropdown-header: #e4e4e4;

    --color-background-upload-image-action: var(--color-hinted-grey-3);
    --color-background-upload-image-action-hover: var(--color-hinted-grey-13);
    --color-background-upload-image-preview: var(--color-hinted-grey-3);
    --color-background-upload-image-preview-action: var(--color-hinted-grey-3);
    --color-background-upload-image-preview-action-hover: var(--color-hinted-grey-13);

    --color-background-dropzone: var(--color-hinted-grey-1);
    --color-background-dropzone-focus: var(--color-blue-alt);
    --color-border-dropzone: var(--color-hinted-grey-4);
    --color-text-dropzone: var(--color-hinted-grey-12);

    --color-background-post-image: var(--color-hinted-grey-3);
    --color-border-post-image: var(--color-hinted-grey-3);
    --color-background-post-image-preview: var(--color-hinted-grey-3);
    --color-border-post-image-preview: var(--color-hinted-grey-3);

    --color-border-post-description: var(--color-hinted-grey-3);

    --color-text-post-tags: #616161;

    --color-background-post-sell-price: #e9dcff;
    --color-text-post-sell-price: var(--color-hinted-grey-5);

    --color-background-post-comment: var(--color-hinted-grey-16);
    --color-text-post-comment-time: #3c3c3c;

    --color-border-top-nav: var(--color-hinted-grey-2);
    --color-border-sidebar: var(--color-hinted-grey-2);
    --color-border-footer: var(--color-hinted-grey-2);

    --color-border-grid-post-image: var(--color-hinted-grey-3);
    --color-border-grid-post-image-hover: var(--color-purple);

    --color-text-body: var(--color-hinted-grey-5);
    --color-text-top-nav: var(--color-hinted-grey-5);
    --color-text-top-nav-hover: #7668cb;

    --color-text-sidebar: var(--color-hinted-grey-5);

    --color-text-section-head: var(--color-hinted-grey-11);
    --color-text-section-sub-head: var(--color-hinted-grey-11);
    --color-text-section-sub-section: var(--color-hinted-grey-10);

    --color-text-post-creator-author: var(--color-hinted-grey-11);
    --color-text-post-creator-character: var(--color-hinted-grey-15);

    --color-text-footer: var(--color-hinted-grey-5);
    --color-text-footer-icon: var(--color-hinted-grey-8);
    --color-text-footer-icon-hover: var(--color-purple);
    --color-text-footer-icon-active: var(--color-purple-dark);
    --color-text-pagination-hover: var(--color-purple);
    --color-text-pagination-active: var(--color-purple);

    --color-text-input-label: var(--color-hinted-grey-10);
    --color-text-input-label-error: var(--color-error);
    --color-text-input-error-message: var(--color-error);
    --color-border-input-error: var(--color-error);

    --color-text-username: var(--color-hinted-grey-11);

    --color-fill-post-like: var(--color-purple-dark);

    --color-background-search-sidebar: #fff;
    --color-border-search-sidebar: var(--color-hinted-grey-2);
    --color-background-search-sidebar-filter: transparent;
    --color-background-search-sidebar-filter-active: transparent;
    --color-border-search-sidebar-filter: var(--color-hinted-grey-2);
    --color-text-search-sidebar-filter-icon: #97a1bb;
    --color-background-search-sidebar-filter-icon: #f2f4f9;

    --color-text-privacy-sub-head: var(--color-hinted-grey-11);
    --color-background-privacy-ccpa: var(--color-hinted-grey-2);
}

/* Dark Theme */
 :root[data-theme="dark"] {
    /* **DARK** THEME DEFINITIONS */
    --color-background-body: var(--color-hinted-grey-17);
    --color-background-accent: var(--color-purple);
    --color-background-accent-alt: var(--color-purple-dark);
    --color-background-accent-secondary: var(--color-blue);
    --color-background-accent-secondary-alt: var(--color-blue-dark);
    --color-background-image-slider-pagination-bullet: var(--color-purple);
    --color-background-button-primary: transparent;    
    --color-background-button-primary-hover: var(--color-halloween);    
    --color-background-button-primary-active: var(--color-halloween-dark);    
    --color-background-button-primary-locked: transparent;    
    --color-text-button-disabled: #b1b1b1; 
    --color-background-button-disabled: #2d2e33; 
    --color-text-button-primary: var(--color-halloween);    
    --color-text-button-primary-hover: var(--color-white);    
    --color-text-button-primary-active: var(--color-white);    
    --color-text-button-primary-locked: #999999;    
    --color-border-button-primary: var(--color-halloween);
    --color-border-button-primary-locked: #e5e5e5;
    
    --color-background-input: rgb(51, 52, 57);
    --color-background-input-active: var(--color-hinted-grey-16);
    --color-border-input: none;
    --color-border-input-focus: none;
    --color-text-input: #fff;

    --color-background-toggle: #72767d;
    --color-background-toggle-checked: var(--color-blue-alt);
    --color-background-toggle-focus: var(--color-blue-alt);
    --color-background-toggle-handle: var(--color-white);

    --color-background-character-table-head: #131313;
    --color-text-character-table-head: #fff;
    --color-background-character-table-row: var(--color-hinted-grey-16);
    --color-background-character-table-row-hover: rgb(51, 52, 57);
    --color-text-character-table: var(--color-hinted-grey-3);

    --color-background-subsection-header: var(--color-hinted-grey-16);
    --color-text-subsection-header: #fff;

    --color-background-grid-post-image: var(--color-hinted-grey-10);

    --color-background-top-nav: var(--color-hinted-grey-16);
    --color-border-sidebar: var(--color-hinted-grey-18);
    --color-background-sidebar: var(--color-hinted-grey-16);
    --color-background-sidebar-item-selected: var(--color-hinted-grey-6);
    --color-background-sidebar-item-hover: #7668cb;
    --color-background-sidebar-item-active: #4e4582;

    --color-background-footer: var(--color-hinted-grey-9);

    --color-background-dropdown: var(--color-hinted-grey-16);
    --color-background-dropdown-notification-hover: var(--color-hinted-grey-17);
    --color-background-dropdown-notification-unread: #3a345f;
    --color-background-dropdown-header: var(--color-hinted-grey-16);
    --color-background-dropdown-notification-button-hover: var(--color-hinted-grey-17);
    --color-background-dropdown-divider: #383a40;
    --color-text-dropdown-primary: #fff;
    --color-text-dropdown-secondary: var(--color-hinted-grey-4);
    --color-text-dropdown-header: #fff;
    --color-text-dropdown-notification-button: var(--color-hinted-grey-3);
    --color-border-dropdown-header: var(--color-hinted-grey-17);
    
    --color-background-upload-image-action: var(--color-hinted-grey-16);
    --color-background-upload-image-action-hover: #52545f;
    --color-background-upload-image-preview: var(--color-hinted-grey-16);
    --color-background-upload-image-preview-action: var(--color-hinted-grey-16);
    --color-background-upload-image-preview-action-hover: #52545f;

    --color-background-dropzone: #232427;
    --color-background-dropzone-focus: var(--color-blue-alt);
    --color-border-dropzone: #131313;
    --color-text-dropzone: var(--color-hinted-grey-3);

    --color-background-post-image: var(--color-hinted-grey-16);
    --color-border-post-image: var(--color-hinted-grey-16);
    --color-background-post-image-preview: var(--color-hinted-grey-16);
    --color-border-post-image-preview: var(--color-hinted-grey-16);

    --color-border-post-description: #505050;

    --color-text-post-tags: var(--color-hinted-grey-20);

    --color-background-post-sell-price: #e5ffdc;
    --color-text-post-sell-price: var(--color-hinted-grey-5);

    --color-background-post-comment: var(--color-hinted-grey-16);
    --color-text-post-comment-time: var(--color-hinted-grey-20);

    --color-border-top-nav: var(--color-hinted-grey-19);
    --color-border-footer: var(--color-hinted-grey-19);

    --color-border-grid-post-image: var(--color-hinted-grey-16);
    --color-border-grid-post-image-hover: var(--color-purple);

    --color-text-body: var(--color-hinted-grey-1);
    --color-text-top-nav: var(--color-hinted-grey-1);
    --color-text-top-nav-hover: #7668cb;

    --color-text-sidebar: #ffffff;

    --color-text-section-head: #fff;
    --color-text-section-sub-head: var(--color-hinted-grey-11);
    --color-text-section-sub-section: #fff;

    --color-text-post-creator-author: #fff;
    --color-text-post-creator-character: #fff;

    --color-text-footer: var(--color-hinted-grey-20);
    --color-text-footer-icon: var(--color-hinted-grey-20);
    --color-text-footer-icon-hover: var(--color-purple);
    --color-text-footer-icon-active: var(--color-purple-dark);
    --color-text-pagination-hover: var(--color-purple);
    --color-text-pagination-active: var(--color-purple);

    --color-text-input-label: #fff;
    --color-text-input-label-error: var(--color-error);
    --color-text-input-error-message: var(--color-error);
    --color-border-input-error: var(--color-error);

    --color-text-username: #ffffff;

    --color-fill-post-like: var(--color-purple);

    --color-background-search-sidebar: #323338;
    --color-border-search-sidebar: #464749;
    --color-background-search-sidebar-filter: transparent;
    --color-background-search-sidebar-filter-active: #2d2e33;
    --color-border-search-sidebar-filter: #464749;
    --color-text-search-sidebar-filter-icon: #fff;
    --color-background-search-sidebar-filter-icon: #4d5056;

    --color-text-privacy-sub-head: #ffffff;
    --color-background-privacy-ccpa: var(--color-hinted-grey-16);
}

* {
    box-sizing: border-box;
    margin: 0;
}

*:before,
*:after {
 box-sizing: border-box;
}

body {
    background: #ffffff;
    background: var(--color-background-body);
    color: #3b3b3b;
    color: var(--color-text-body);
    /* font-family: 'Segoe UI', 'Lato', sans-serif; */
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    text-decoration: underline;
}

input[type=text], input[type=password], input[type=number], input[type=email] {
    height: 35px;
    display: inline-block;
    padding: 5px 12px;
    font-size: 14px;
    line-height: 20px;
}

input[type=text]:focus, input[type=password]:focus, input[type=number]:focus, input[type=email]:focus {
    outline: none;
}

select {
    height: 35px;
    max-width: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    border: 0 none;
    padding: 0 10px;
    color: #3b3b3b;
    color: var(--color-text-input);
    vertical-align: middle;
    background: #f7f7f7;
    background: var(--color-background-input);
    border: 1px solid #e8e8e8;
    border: 1px solid var(--color-border-input);
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    -webkit-transition-property: color,background-color,border;
    transition-property: color,background-color,border;
    border-radius: 5px;
    box-shadow: inset 0 1px 0 rgba(225,228,232,.2);
}

select:focus {
    outline: none;
    background-color: #f7f7f7;
    background-color: var(--color-background-input);
    /* color: #666; */
    /* border-color: #1e87f0; */
    border-color: #9658fe;
    border-color: var(--color-border-input-focus);
}

.submit {
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-transition: .1s ease-in-out;
    transition: .1s ease-in-out;
    -webkit-transition-property: color,background-color,border-color;
    transition-property: color,background-color,border-color;
    background-color: transparent;
    background-color: var(--color-background-button-primary);
    color: #9658fe;
    color: var(--color-text-button-primary);
    border: 1px solid #9658fe;
    border: 1px solid var(--color-border-button-primary);
    border-radius: 5px;
}

.submit:hover {
    background: #9658fe;
    background: var(--color-background-button-primary-hover);
    color: #ffffff;
    color: var(--color-text-button-primary-hover);
}

.submit:focus {
    background: #6d40b9;
    background: var(--color-background-button-primary-active);
    color: #ffffff;
    color: var(--color-text-button-primary-active);
    outline: none;
}

.submit:disabled {
    background-color: transparent;
    background-color: var(--color-background-button-primary-locked);
    color: #999999;
    color: var(--color-text-button-primary-locked);
    border-color: #e5e5e5;
    border-color: var(--color-border-button-primary-locked);
}

.pagination {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 3rem;
    padding: 0.2rem;
    list-style: none;
    -webkit-justify-content: center;
            justify-content: center;
    color: #999;
}

.pagination li:first-child {
    padding-left: 0px;
}

.pagination li {
    -webkit-flex: none;
            flex: none;
    padding-left: 20px;
    position: relative;
}

.pagination li.disabled {
    display: none;
}

.pagination>*>* {
    display: block;
    -webkit-transition: color .1s ease-in-out;
    transition: color .1s ease-in-out;
}

.pagination>*>*:hover, .pagination>*>*:focus {
    color: #666;
    text-decoration: none;
    outline: none;
}   

.pagination a {
    cursor: pointer;
}

.pagination a:hover {
    color: #9658fe;
    color: var(--color-text-pagination-hover);
}

.pagination > li.active {
    color: #9658fe;
    color: var(--color-text-pagination-active);
}

.pagination > li.active a {
    font-size: 1.4rem;
    font-weight: 700;
    /* border-bottom: 1px solid var(--color-text-pagination-active); */
}

.Toastify__toast {
    border-radius: 5px;
    /* color: #1a1a1a; */
    text-align: center;
}

.Toastify__progress-bar {
    /* background: #1a1a1a; */
}

/* SCROLL BAR DARK MODE */
/* https://stackoverflow.com/questions/47576815/how-to-add-arrows-with-webkit-scrollbar-button */

 :root[data-theme="dark"] ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

 :root[data-theme="dark"] ::-webkit-scrollbar-corner,
 :root[data-theme="dark"] ::-webkit-scrollbar-track {
    background-color: rgb(64, 64, 64);
}

 :root[data-theme="dark"] ::-webkit-scrollbar-thumb {
    background-color: rgb(96, 96, 96);
    background-clip: padding-box;
    border: 2px solid transparent;
}

:root[data-theme="dark"] ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(112, 112, 112);
}

:root[data-theme="dark"] ::-webkit-scrollbar-thumb:active {
    background-color: rgb(128, 128, 128);
}

/* Buttons */
:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button {
    background-color: rgb(64, 64, 64);

    display: block;
    background-size: 10px;
    background-repeat: no-repeat;
}

/* Up */
:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 12px;
    width: 16px;
    background-position: center 4px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 12px;
    width: 16px;
    background-position: center 2px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:vertical:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:decrement {
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");

}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:increment {
    height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
}

:root[data-theme="dark"] ::-webkit-scrollbar-button:single-button:horizontal:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
}

