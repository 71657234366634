.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;

    background: var(--color-background-footer);
    border-top: 1px solid var(--color-border-footer);
    color: var(--color-text-footer);
    font-size: 16px;
}

.footer .contents {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.footer .contents .apb-dot-socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer .contents .apb-dot-socials a {
    display: flex;
}

.footer .contents .apb-dot-socials .icon {
    display: block;
    color: var(--color-text-footer-icon);
    transition: .2s color;
    margin: 4px;
}

.footer .contents .apb-dot-socials .icon:hover {
    color: var(--color-text-footer-icon-hover);
}

.footer .contents .apb-dot-socials .icon:active {
    color: var(--color-text-footer-icon-active);
}

.footer .contents .copyright {
    text-align: center;
    font-size: .7em;
}

.footer .contents .privacy {
    font-size: .7em;
    margin-top: .5em;
    text-align: center;
}