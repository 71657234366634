.privacy-sub-head {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-text-privacy-sub-head);
    text-transform: uppercase;
    margin: 40px 0 20px 0;
}

.privacy-p {
    margin: 10px auto;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: 1px;
}

.privacy-link, .privacy-link:hover {
    border-bottom: 2px solid #9658fe;
    text-decoration: none;
}

.privacy-ccpa {
    max-width: 1084px;
    margin: 10px auto;
    padding: 20px;
    background-color: var(--color-background-privacy-ccpa);
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: 1px;
}

.privacy-ccpa b {
    font-weight: 600;
}