.image-selector {
    position: relative;
    margin: 4px;
}

.image-selector .preview-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image-selector .position-controls {
    display: flex;
}

.image-selector .position-controls .left {
    display: inline-flex;
}

.image-selector .position-controls .right {
    display: inline-flex;
    margin-left: auto;
}