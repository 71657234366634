/* 500 */

.register-form {
    margin: 0 auto;
    max-width: 500px;
}

.register-form .field {
    margin-bottom: 1rem;
}

.register-form .field input {
    width: 100%;
}

.register-form .field .error-message {
    margin: 10px 0;
    font-size: .8rem;
    color: var(--color-text-input-error-message);
}

.register-form .register-button {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
}

.register-form .register-button .submit {
    width: 100%;
}